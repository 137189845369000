import React from "react";

// reactstrap components
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { connect } from 'react-redux';
import { setPosts  } from '../../actions/postActions';

class PaginationSection extends React.Component {
  getPostsByPage(e, page) {
    e.preventDefault();
    var headers = this.getHeaders();
    this.props.setPosts(page, headers);
  }

  getPostsPrevious(e) {
    e.preventDefault();
    var currentPage = this.props.pagination.page;
    var previousPage = currentPage - 1;
    var headers = this.getHeaders();
    this.props.setPosts(previousPage, headers);
  }

  getPostsNext(e) {
    e.preventDefault();
    var currentPage = this.props.pagination.page;
    var nextPage = currentPage + 1;
    var headers = this.getHeaders();
    this.props.setPosts(nextPage, headers);
  }

  getHeaders() {
    return {
      Authorization: 'application/json',
     "access-token": this.props.auth.jwt.token,
     client: this.props.auth.jwt.client,
     uid: this.props.auth.email
    };
  }

  renderPages() {
    var pages = this.props.pagination.pages;
    var currentPage = this.props.pagination.page;
    var data = [];
    if (pages > 0) {
      for(let page=1; page<=pages; ++page) {
        var active = page === currentPage ? 'active' : '';
        data.push(
          <PaginationItem key={page} className={active}>
            <PaginationLink href="#" onClick={e => this.getPostsByPage(e, page)}>
              { page }
            </PaginationLink>
          </PaginationItem>
        )
      }
    }
    return data;
  }
  render() {
    return (
      <>
        <nav aria-label="Page navigation example">
          <Pagination>
            <PaginationItem disabled={this.props.previousPage}>
              <PaginationLink href="#" onClick={e => this.getPostsPrevious(e)}>
                <i className="fa fa-angle-left" />
              </PaginationLink>
            </PaginationItem>
            { this.renderPages() }
            <PaginationItem disabled={this.props.nextPage}>
              <PaginationLink href="#" onClick={e => this.getPostsNext(e)}>
                <i className="fa fa-angle-right" />
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    pagination: state.postPagination.pagination,
    previousPage: state.postPagination.previousPage,
    nextPage: state.postPagination.nextPage,
    auth: state.user,
  };
}

export default connect(mapStateToProps, {
  setPosts
})(PaginationSection);
