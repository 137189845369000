export const es = {
  buttons: {
    sendMessage: 'Enviar mensaje'
  },
  nav: {
    services: 'Servicios',
    offer: 'Oferta',
    why: 'Por qué nosotros?',
    about: 'Acerca de',
    contact: 'Contacto'
  },
  title: {
    main: 'Fábrica de Software',
    second: 'Innovando soluciones tecnológicas',
    third: 'Administra tu negocio e incrementa tus ganancias',
    fourth: 'La era digital es AHORA!'
  },
  cards: {
    web: {
      title: 'Páginas Web',
      description: 'Desde una página de anuncio hasta una web compleja y robusta'
    },
    mobile: {
      title: 'Aplicaciones Móviles',
      description: 'Administra tus negocios desde tu mano'
    },
    ecommerce: {
      title: 'Mercados',
      description: 'Muestra tus productos en internet e incrementa tus ventas'
    }
  },
  offer: {
    title: 'Nos preocupamos por ti',
    description: 'En alsumi nos preocupan nuestros clientes por lo cual ofrecemos estos servicios totalmente GRATIS!',
    first: 'Análisis, cotización y propuesta de trabajo',
    second: 'Capacitación del personal a cargo de la tecnología',
    third: 'Soporte técnico todo 1 año después de finalizar el proyecto'
  },
  technology: {
    title: 'Usamos tecnologías modernas',
    description: 'Nuestro equipo es experto desarrollando aplicaciones para cualquier tipo de empresa'
  },
  why: {
    title: 'Diseño moderno',
    description: 'Aseguramos calidad de software en cualquier producto que necesites, actualiza tu negocio a la era digital',
    cards: {
      awesome: {
        title: 'Soporte',
        description: `El equipo de alsumi puede asesorarte de principio a fin en todo el proceso del proyecto.`
      },
      safe: {
        title: 'Seguridad',
        description: 'Mantener tu información segura es de suma importancia para nosotros.'
      },
      fast: {
        title: 'Rapidez',
        description: 'Todas las soluciones son completamente rápidas, agiliza tus procesos y ahorra tiempo.'
      },
      easy: {
        title: 'Facilidad',
        description: 'Aplicaciones intuitivas y fáciles de usar para todos nuestros clientes.'
      }
    }
  },
  about: {
    descriptionOne: `Somos un equipo en una amplia gama de plataformas y tecnologías,
    sean cuales sean tus requerimientos, talento e ingeniería a tu servicio.
    Garantizamos la entrega dentro de sus plazos y presupuesto. Nuestra empresa brinda las soluciones de software
    más relevantes para la gestión de proyectos.`,
    descriptionTwo: `Las herramientas de gestión de proyectos y las tecnologías de TI
    más relevantes impulsarán la productividad de su trabajo y, lo más importante,
    cumplirán con los requisitos de sus clientes al hacer que el flujo de trabajo sea perfectamente transparente.`,
    descriptionThree: `Ofrecemos la solución tecnológica adecuada al precio adecuado para las pequeñas, medianas y grandes empresas.
    Estamos altamente comprometidos en brindar servicios estándar a todos nuestros clientes
    sin importar el tamaño o tipo de proyecto, todos nuestros clientes son igualmente importantes para nosotros.`
  },
  contact: {
    title: 'Contáctanos',
    description: 'A través de',
    number: {
      title: {
        ver: 'Boca del Río, Veracruz',
        cancun: 'Cancún, Quintana Roo'
      },
      cancun: '782 132 90 36',
      ver: '782 220 90 27'
    },
    email: {
      title: 'Correo electrónico',
      value: 'info@alsumi.mx'
    },
    form: {
      title: 'Necesitas un software?',
      description: 'Ingresa tu información',
      name: 'Nombres',
      lastName: 'Apellidos (Opcional)',
      email: 'Correo electrónico',
      number: 'Número telefónico',
      type: 'Tipo de proyecto',
      message: 'Escribe un mensaje'
    }
  },
  actions: {
    posts: {
      create: {
        sucess: {
          header: 'Éxito',
          message: 'Nos has enviado un mensaje',
          text: 'Te contactaremos lo más pronto posible!'
        },
        error: {
          header: 'Error',
          message: 'Hubo un error inesperado, por favor intenta más tarde',
          text: 'Error ocurrido tratando de crear un contacto'
        }
      }
    }
  },
  validations: {
    posts: {
      name: {
        empty: 'El nombre no puede ir en blanco',
        maximum: 'Máximo 255 caracteres para el nombre'
      },
      lastName: {
        maximum: 'Máximo 255 caracteres para los apellidos'
      },
      email: {
        maximum: 'Máximo 255 caracteres para el correo electrónico',
        invalid: 'Formato de correo electrónico inválido',
        empty: 'El correo electrónico no puede ir en blanco'
      },
      contactNumber: {
        empty: 'El numero de contacto no puede ir en blanco',
        size: 'El numero de contacto es de 10 digitos'
      },
      typeProject: {
        empty: 'El tipo de proyecto no puede ir en blanco'
      },
      message: {
        empty: 'El mensaje no puede ir en blanco',
        maximum: 'Máximo 6000 caracteres para el mensaje',
      }
    }
  }
};
