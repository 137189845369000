import React from "react";

// reactstrap components
import { Card, Container, Row, Col, Button } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import { ToastContainer } from "react-toastify";

// redux
import { connect } from 'react-redux';
import { setSummary  } from '../../actions/postActions';
import { cleanJwt  } from '../../actions/authActions';
import { togglePostsModal  } from '../../actions/modalActions';

import history from '../../history';
import PostModal from '../../components/Post/PostModal';

class Home extends React.Component {
  UNSAFE_componentWillMount() {
    var jwt = this.props.auth.jwt;
    if (jwt === null) {
      history.push('login');
    } else {
      var headers = this.getHeaders();
      this.props.setSummary(headers);
    }
  }

  toggleModal(state) {
    this.props.togglePostsModal();
  };

  getHeaders() {
    return {
      Authorization: 'application/json',
     "access-token": this.props.auth.jwt.token,
     client: this.props.auth.jwt.client,
     uid: this.props.auth.email
    };
  }

  myAge() {
    var wasBornYear = 1993;
    var currentYear = new Date().getFullYear();
    return currentYear - wasBornYear;
  }
  render() {
    return (
      <>
        <DemoNavbar history={this.props.history} />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="6">
                      <div className="card-profile-image">
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={require("assets/img/theme/team-4-800x800.jpg")}
                        />
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="6">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">{this.props.postSummary.total}</span>
                          <span className="description">Posts</span>
                        </div>
                        <div>
                          <span className="heading">{this.props.postSummary.read}</span>
                          <span className="description">Read</span>
                        </div>
                        <div>
                          <span className="heading">{this.props.postSummary.unread}</span>
                          <span className="description">Unread</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-5">
                    <h3>
                      Joel Garcia{" "}
                      <span className="font-weight-light">, { this.myAge() }</span>
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Poza Rica, Veracruz, Mexico
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Co-founder and Software Developer
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      Proudly Mexican
                    </div>
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                      <Button
                        block
                        className="mb-3"
                        color="secondary"
                        type="button"
                        onClick={() => this.toggleModal()}
                      >
                        Show Posts
                      </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
          <PostModal />
        </main>
        <ToastContainer autoClose={2000} />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.user,
    postSummary: state.postSummary
  };
}

export default connect(mapStateToProps, {
  cleanJwt,
  togglePostsModal,
  setSummary
})(Home);
