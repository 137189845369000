import React from "react";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import ServicesHeader from "components/Headers/ServicesHeader.js";
import Offer from "components/Offers/Offer.js";
import WhyUs from "components/About/WhyUs.js";
import AboutUs from "components/About/AboutUs.js";
import ContactUs from "components/Contact/ContactUs.js";
import Form from "components/Contact/Form.js";
import Technology from 'components/About/Technology';

// toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Landing extends React.Component {

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <DemoNavbar/>
        <main ref="main">
          <ServicesHeader/>
          <Offer/>
          <Technology />
          <WhyUs/>
          <AboutUs/>
          <ContactUs/>
          <Form/>
        </main>
        <CardsFooter />
        <ToastContainer autoClose={2000} />
      </>
    );
  }
}

export default Landing;
