/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";
import { connect } from 'react-redux';
import { logout  } from '../../actions/authActions';
import Select from 'react-select';
import { i18 } from '../../internationalization';
import { setI18 } from '../../actions/i18Actions';

class DemoNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseClasses: "",
      collapseOpen: false,
      i18Value: this.props.i18,
      logo: require("assets/img/brand/logo-gris-484.png"),
      prevSize: 0
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    this.setState({prevSize: window.scrollY});
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  customStyles() {
    const customStyles = {
      control: base => ({
        ...base,
        height: 20,
        width: 80
      }),
      menu: base => ({
        top: "100%",
        width: 80,
        marginBottom: 8,
        marginTop: 8,
        position: 'absolute',
        zIndex: 1,
        borderRadius: 4,
        boxSizing: 'border-box',
        boxShadow: '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)'
      })
    };
    return customStyles;
  }

  handleScroll(e) {
    const window = e.currentTarget;
    if (this.state.prevSize > window.scrollY && window.scrollY === 0) {
      this.setState({logo: require("assets/img/brand/logo-gris-484.png")});
    }
    else if (this.state.prevSize > window.scrollY) {
      this.setState({logo: require("assets/img/brand/logo-blanco-484.png")});
    }

    this.setState({prevSize: window.scrollY});
  }

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  getHeaders() {
    return {
      Authorization: 'application/json',
     "access-token": this.props.auth.jwt.token,
     client: this.props.auth.jwt.client,
     uid: this.props.auth.email
    };
  }

  logoutAction() {
    var headers = this.getHeaders();
    this.props.logout({jwt: null, email: '', name: ''}, headers);
  }

  renderIconByValue(value) {
    if (value === 'en') {
      return <img
                alt="..."
                className="rounded-circle img-center image-i18"
                src={require("assets/img/icons/common/ingles.png")}
             />;
    } else {
      return <img
                alt="..."
                className="rounded-circle img-center image-i18"
                src={require("assets/img/icons/common/espanol.png")}
             />;
    }
  }

  options() {
    return [
      { value: 'es',
        label: <img
                  alt="..."
                  className="rounded-circle img-center image-i18"
                  src={require("assets/img/icons/common/espanol.png")}
               />
      },
      { value: 'en',
        label: <img
                alt="..."
                className="rounded-circle img-center image-i18"
                src={require("assets/img/icons/common/ingles.png")}
               />
      }
    ];
  }

  renderSelectI18() {
    return (
      <Select
        styles={this.customStyles()}
        placeholder={this.renderIconByValue(this.state.i18Value)}
        value={this.state.i18Value}
        isSearchable={false}
        onChange={(e) => this.syncFieldSelect(e)}
        options={this.options()}>
      </Select>
    );
  }

  syncFieldSelect(e) {
    this.props.setI18(e.value);
    this.setState({i18Value: e.value});
  }

  renderLogoutButton() {
    var jwt = this.props.auth.jwt;
    if (jwt === null) {
      return '';
    } else {
      return (
        <Button
          block
          className="btn"
          color="secondary"
          type="button"
          onClick={() => this.logoutAction()}
        >
          Logout
        </Button>
      );
    }
  }

  logo() {
    return(
      <img
        alt="alsumi"
        src={this.state.logo}
      />
    );
  }

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" href="#services-section">
                {this.logo()}
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/logo-gris-484.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink href="#services-section">
                      {i18[this.props.i18].nav.services}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="#offer-section">
                      {i18[this.props.i18].nav.offer}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="#why-us-section">
                      {i18[this.props.i18].nav.why}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="#about-us-section">
                      {i18[this.props.i18].nav.about}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink href="#contact-us-section">
                      {i18[this.props.i18].nav.contact}
                    </NavLink>
                  </NavItem>

                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://youtube.com/channel/UCLsBUjFME0EXOHwXiV2gzHA"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fa fa-youtube-play" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Youtube
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip184698705">
                      Follow us on Youtube
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.facebook.com/alsumi.mx"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589074">
                      Follow us on Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem className="d-lg-block ml-lg-4">
                    {this.renderLogoutButton()}
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
              {this.renderSelectI18()}
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.user,
    i18: state.i18
  };
}

export default connect(mapStateToProps, {
  logout,
  setI18
})(DemoNavbar);
