import { combineReducers } from 'redux';
import postReducer from './postReducer';
import userReducer from './userReducer';
import i18Reducer from './i18';
import postSummaryReducer from './postSummaryReducer';
import modalsReducer from './modalReducer';
import postPaginationReducer from './postPaginationReducer';

export default combineReducers({
  posts: postReducer,
  user: userReducer,
  i18: i18Reducer,
  postSummary: postSummaryReducer,
  modals: modalsReducer,
  postPagination: postPaginationReducer,
});
