import React from 'react';

import { Button, Row, FormGroup,
  InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import Select from 'react-select';
import { connect } from 'react-redux';
import { setPosts  } from '../../actions/postActions';

const typeOptions = [
  { value: null, label: 'All' },
  { value: 0, label: 'Web Application' },
  { value: 1, label: 'Mobile Application' },
  { value: 2, label: 'Ecommerce' }
];

const statusOptions = [
  { value: null, label: 'All' },
  { value: 0, label: 'Created' },
  { value: 1, label: 'Seen' },
  { value: 2, label: 'Communication' },
  { value: 3, label: 'Working' },
  { value: 4, label: 'Finished' },
  { value: 5, label: 'Rejected' }
];

const customStyles = {
  control: base => ({
    ...base,
    height: 44,
    width: 140
  })
};

class Filter extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      typeOfProject: null,
      typeOfProjectName: 'Type',
      status: null,
      statusName: 'Status'
    };
    this.syncFieldSelectType = this.syncFieldSelectType.bind(this);
    this.syncFieldSelectStatus = this.syncFieldSelectStatus.bind(this);
  }
  renderStatusValue(status) {
    var obj = statusOptions.find(o => o.label.toLowerCase() === status);
    return obj.value;
  }
  handleReactDatetimeChange(who, date) {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else {
      this.setState({
        [who]: date
      });
    }
  }

  getHeaders() {
    return {
      Authorization: 'application/json',
     "access-token": this.props.auth.jwt.token,
     client: this.props.auth.jwt.client,
     uid: this.props.auth.email
    };
  }

  getParams() {
    return {
      project_type: this.state.typeOfProject,
      status: this.state.status,
      start_date: this.state.startDate,
      end_date: this.state.endDate
    }
  }

  filterPosts() {
    var headers = this.getHeaders();
    var page = 1; // this is first page pagination
    var params = this.getParams();
    this.props.setPosts(page, headers, params);
  }

  getClassNameReactDatetimeDays(date) {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  }

  syncFieldSelectType(e) {
    this.setState({typeOfProject: e.value, typeOfProjectName: e.label});
  }

  syncFieldSelectStatus(e) {
    this.setState({status: e.value, statusName: e.label});
  }

  render() {
    return(
      <Row className='mb-3 justify-content-center'>
        <Select
          styles={customStyles}
          placeholder={this.state.typeOfProjectName}
          value={this.state.typeOfProject}
          onChange={(e) => this.syncFieldSelectType(e)}
          options={typeOptions} />
          <Select
            styles={customStyles}
            className='ml-2'
            placeholder={this.state.statusName}
            value={this.state.status}
            onChange={(e) => this.syncFieldSelectStatus(e)}
            options={statusOptions} />
          <FormGroup className='ml-2'>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "Start Date"
                }}
                value={this.state.startDate}
                timeFormat={false}
                onChange={e =>
                  this.handleReactDatetimeChange("startDate", e)
                }
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  classes += this.getClassNameReactDatetimeDays(
                    currentDate
                  );
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                inputProps={{
                  placeholder: "End Date"
                }}
                className="rdtPickerOnRight"
                value={this.state.endDate}
                timeFormat={false}
                onChange={e =>
                  this.handleReactDatetimeChange("endDate", e)
                }
                renderDay={(props, currentDate, selectedDate) => {
                  let classes = props.className;
                  classes += this.getClassNameReactDatetimeDays(
                    currentDate
                  );
                  return (
                    <td {...props} className={classes}>
                      {currentDate.date()}
                    </td>
                  );
                }}
              />
            </InputGroup>
          </FormGroup>
          <Button
            className="btn-icon btn-2 ml-1 btn-filter"
            color="secondary"
            type="button"
            onClick={() => this.filterPosts()}
          >
            <span className="btn-inner--icon">
              <i className="fa fa-filter" />
            </span>
          </Button>
      </Row>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.user,
  };
}

export default connect(mapStateToProps, {
  setPosts
})(Filter);
