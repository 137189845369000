import { toast } from "react-toastify";

export const showToast = function(alert) {
  if(alert.error) {
    let str = `${alert.message} \n`;
    if(alert.errors.length > 0) {
      for (let i = 0; i < alert.errors.length; ++i) {
        str += `${i+1}) ${alert.errors[i]} \n`;
      }
      toast.error(str);
    } else {
      toast.error(str);
    }
  } else {
    toast.success(alert.message);
  }
};
