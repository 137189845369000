import { SET_JWT, CLEAN_JWT } from "../const/authConst";
import http from '../apis/alsumi';
import { showToast } from '../toast/toastify';
import history from '../history';

export const cleanJwt = (jwt) => dispatch => {
  dispatch({ type: CLEAN_JWT, payload: jwt });
};

export const logout = (jwt, headers) => async dispatch => {
  try {
    const response = await http.delete('/authentication/logout', { headers: headers });
    dispatch({ type: CLEAN_JWT, payload: jwt });
    let result = response.data;
    let alert  = {
      error: result.error,
      message: result.data.message,
      errors: []
    };
    showToast(alert);
    setTimeout(() => {
       history.push('login');
     }, 500);
  } catch (e) {
    var data = { jwt: null, email: '', name: '' };
    dispatch({ type: CLEAN_JWT, payload: data });
    history.push('login');
  }
};

export const createUser = (params) => async dispatch => {
  try {
    const response = await http.post('/authentication/register', params);
    let result = response.data;
    let alert  = {
      error: result.error,
      message: result.data.message,
      errors: []
    };
    showToast(alert);
    setTimeout(() => {
       history.push('login');
     }, 500);
  } catch (e) {
    let result = e.response;
    var alert = {};
    if (e.response === undefined) {
      alert = {
        error: true,
        message: 'There was an error connecting to the server',
        errors: ''
      };
    } else {
      alert  = {
        error: result.data.error,
        message: result.data.data.message,
        errors: result.data.data.errors
      };
    }
    showToast(alert);
  }
};

export const loginUser = (params) => async dispatch => {
  try {
    const response = await http.post('/authentication/login', params);
    let result = response.data;
    let alert  = {
      error: result.error,
      message: result.data.message,
      errors: []
    };
    showToast(alert);
    var info = { email: result.data.email, name: result.data.name, jwt: result.data.token }
    dispatch({ type: SET_JWT, payload: info });
    setTimeout(() => {
       history.push('home');
     }, 500);
  } catch (e) {
    let result = e.response;
    var alert = {};
    if (e.response === undefined) {
      alert = {
        error: true,
        message: 'There was an error connecting to the server',
        errors: ''
      };
    } else {
      alert  = {
        error: result.data.error,
        message: result.data.data.message,
        errors: result.data.data.errors
      };
    }
    showToast(alert);
  }
}
