import { TOGGLE_POSTS_MODAL, TOGGLE_POSTS_ALERT_MODAL, SET_CONTENT_POST_MODAL } from "../const/modalConst";

export const togglePostsModal = () => dispatch => {
  dispatch({ type: TOGGLE_POSTS_MODAL });
};

export const togglePostAlertModal = () => dispatch => {
  dispatch({ type: TOGGLE_POSTS_ALERT_MODAL });
};

export const setContentPostModal = (obj) => dispatch => {
  dispatch({ type: SET_CONTENT_POST_MODAL, payload: obj });
};
