import React from "react";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
import { i18 } from '../../internationalization';
import { connect } from 'react-redux';

class WhyUs extends React.Component {
  render() {
    return (
      <section className="section pb-0" id="why-us-section">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="text-center" lg="12">
              <h4 className="display-3 mt-4">{i18[this.props.i18].why.title}</h4>
              <p>
                {i18[this.props.i18].why.description}
              </p>
            </Col>
            <Col className="order-lg-1" lg="6">
              <Card className="shadow shadow-lg--hover mt-5">
                <CardBody>
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                        <i className="fa fa-support" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h5 className="title text-success">
                        {i18[this.props.i18].why.cards.awesome.title}
                      </h5>
                      <p>
                        {i18[this.props.i18].why.cards.awesome.description}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="shadow shadow-lg--hover mt-5 easy-to-work-card">
                <CardBody>
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-shape bg-gradient-danger rounded-circle text-white">
                        <i className="fa fa-shield" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h5 className="title text-danger">
                        {i18[this.props.i18].why.cards.safe.title}
                      </h5>
                      <p>
                        {i18[this.props.i18].why.cards.safe.description}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-lg-1" lg="6">
              <Card className="shadow shadow-lg--hover mt-5">
                <CardBody>
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                        <i className="fa fa-bolt" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h5 className="title text-warning">
                        {i18[this.props.i18].why.cards.fast.title}
                      </h5>
                      <p>
                        {i18[this.props.i18].why.cards.fast.description}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="shadow shadow-lg--hover mt-5 easy-to-work-card">
                <CardBody>
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-shape bg-gradient-info rounded-circle text-white">
                        <i className="fa fa-handshake-o" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h5 className="title text-info">
                        {i18[this.props.i18].why.cards.easy.title}
                      </h5>
                      <p>
                        {i18[this.props.i18].why.cards.easy.description}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="fill-white"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    i18: state.i18
  };
}

export default connect(mapStateToProps, {})(WhyUs);
