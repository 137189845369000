import { SET_I18 } from "../const/i18Const";

export default (state = 'es', action) => {
  switch (action.type) {
    case SET_I18:
      return action.payload;
    default:
      return state;
  }
};
