import { TOGGLE_POSTS_MODAL, TOGGLE_POSTS_ALERT_MODAL, SET_CONTENT_POST_MODAL } from "../const/modalConst";

const INITIAL_STATE = {
  posts: false,
  postAlert: false,
  types: {
    postAlert: {
      type: 'default',
      content: {
        header: 'default header',
        message: 'default message',
        text: 'default text',
        errors: []
      }
    }
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_POSTS_MODAL:
      return {
        ...state,
        posts: !state.posts
      };
    case TOGGLE_POSTS_ALERT_MODAL:
      return {
        ...state,
        postAlert: !state.postAlert
      };
    case SET_CONTENT_POST_MODAL:
      return {
        ...state,
        types: {...state.types,
          postAlert: action.payload }
      };
    default:
      return state;
  }
};
