export const en = {
  buttons: {
    sendMessage: 'Send Message'
  },
  nav: {
    services: 'Services',
    offer: 'Offer',
    why: 'Why us?',
    about: 'About us',
    contact: 'Contact'
  },
  title: {
    main: 'Software Factory',
    second: 'Innovating technological solutions',
    third: 'Manage your business and increase your profits',
    fourth: 'The digital age is NOW'
  },
  cards: {
    web: {
      title: 'Websites',
      description: 'From a landing page to a complex and robust application'
    },
    mobile: {
      title: 'Mobile applications',
      description: 'Manage your business from your hand'
    },
    ecommerce: {
      title: 'Ecommerce',
      description: 'Show your products on the internet and increase your sales'
    }
  },
  offer: {
    title: 'We care about you',
    description: 'At alsumi we care about our clients, which is why we offer these services totally FREE!',
    first: 'Analysis, quotation and job proposal',
    second: 'Training of people in charge of technology',
    third: 'Technical support all 1 year after the end of the project'
  },
  technology: {
    title: 'We use modern technologies',
    description: 'Our team is expert developing applications for any type of company'
  },
  why: {
    title: 'Modern design',
    description: 'We ensure software quality in any product you need, update your business to the digital age',
    cards: {
      awesome: {
        title: 'Support',
        description: `Alsumi team can advise you from beginning to the end trough the project process.`
      },
      safe: {
        title: 'Security',
        description: 'Keeping your information safe is of the utmost importance to us.'
      },
      fast: {
        title: 'Speed',
        description: 'All solutions are completely fast, streamline your processes and save time'
      },
      easy: {
        title: 'Ease',
        description: 'Intuitive and easy-to-use applications for all our clients'
      }
    }
  },
  about: {
    descriptionOne: `We are a team in a wide range of platforms and technologies,
    whatever your requirements, talent and engineering at your service.
    We guarantee delivery within your deadlines and budget. Our company provides software solutions
    most relevant to project management.`,
    descriptionTwo: `Project management tools and IT technologies
    more relevant will boost your work productivity and, most importantly,
    they will meet their clients' requirements by making the workflow perfectly seamless.`,
    descriptionThree: `We offer the right technological solution at the right price for small, medium and large companies.
    We are highly committed to providing standard services to all of our clients
    Regardless of the size or type of project, all of our clients are equally important to us.`
  },
  contact: {
    title: 'Contact us',
    description: 'Through',
    number: {
      title: {
        ver: 'Boca del Río, Veracruz',
        cancun: 'Cancún, Quintana Roo'
      },
      cancun: '782 132 90 36',
      ver: '782 220 90 27'
    },
    email: {
      title: 'Email',
      value: 'info@alsumi.mx'
    },
    form: {
      title: 'Do you need a software?',
      description: 'Enter your information',
      name: 'Name',
      lastName: 'Last Name (Opcional)',
      email: 'Email',
      number: 'Telephone Number',
      type: 'Type of project',
      message: 'Write a message'
    }
  },
  actions: {
    posts: {
      create: {
        sucess: {
          header: 'Sucess',
          message: 'You have sent us a message',
          text: 'We are going to contact you as soon as possible!'
        },
        error: {
          header: 'Error',
          message: 'There was an unexpected error, please try again later.',
          text: 'Error trying to create a contact'
        }
      }
    }
  },
  validations: {
    posts: {
      name: {
        empty: 'Name can not be empty',
        maximum: 'Name maximum 255 characters'
      },
      lastName: {
        maximum: 'Last Name maximum 255 characters'
      },
      email: {
        maximum: 'Email maximum 255 characters',
        invalid: 'Email format invalid',
        empty: 'Email can not be empty'
      },
      contactNumber: {
        empty: 'Contact number can not be empty',
        size: 'Contact number is 10 characters'
      },
      typeProject: {
        empty: 'Type of project can not be empty'
      },
      message: {
        empty: 'Message can not be empty',
        maximum: 'Message maximum 6000 characters',
      }
    }
  }
};
