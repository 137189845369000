import React from 'react';
import { connect } from 'react-redux';
import PostList from './PostList';
import { Button, Modal } from "reactstrap";
import { togglePostsModal  } from '../../actions/modalActions';
import Filter from './Filter';
import PaginationSection from '../Pagination';

class PostModal extends React.Component {
  toggleModal() {
    this.props.togglePostsModal();
  };

  render() {
    return(
      <section>
        <Modal
          className="modal-dialog-centered posts-modal"
          isOpen={this.props.isOpen}
          toggle={() => this.toggleModal()}
        >

          <div className="modal-header">
            <h4 className="modal-title" id="modal-title-default">
              Posts
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Filter />
            <PostList />
            <PaginationSection />
          </div>
          <div className="modal-footer">
            <Button
              className="ml-auto"
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal()}
            >
              Close
            </Button>
          </div>
        </Modal>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isOpen: state.modals.posts
  };
}

export default connect(mapStateToProps, {
  togglePostsModal
})(PostModal);
