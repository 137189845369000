import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Row } from "reactstrap";
import { togglePostAlertModal  } from '../../actions/modalActions';
import { ListGroup, ListGroupItem } from 'reactstrap';

class PostModalAlert extends React.Component {
  toggleModal() {
    this.props.togglePostAlertModal();
  };

  typeModal() {
    return `modal-dialog-centered modal-${this.props.type}`;
  }

  renderModalBody() {
    var type = this.props.type;
    if (type === 'success') {
      return(
        <Row className="justify-content-center">
          <h4 className="heading mt-4">{this.props.content.message}!</h4>
          <p>{this.props.content.text}.</p>
        </Row>
      );
    } else {
      var errors = this.props.content.errors;
      var items = [];
      for(let i=0; i<errors.length; ++i) {
        items.push(<ListGroupItem>{i}</ListGroupItem>);
      }

      return(
        <Row className="justify-content-center">
          <h4 className="heading mt-4">{this.props.content.message}!</h4>
          <p>{this.props.content.text}.</p>
          <ListGroup className='list-group-modal-danger text-center'>
            { items }
          </ListGroup>
        </Row>
      );
    }
  }

  render() {
    return(
      <section>
        <Modal
          className={this.typeModal()}
          isOpen={this.props.isOpen}
          toggle={() => this.toggleModal()}
          backdrop="static"
        >

          <div className="modal-header">
            <h4 className="modal-title" id="modal-title-notification">
              {this.props.content.header}!
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <i className="ni ni-bell-55 ni-3x" />
            </div>
            {this.renderModalBody()}
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              color="default"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal()}
            >
              Close
            </Button>
          </div>
        </Modal>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isOpen: state.modals.postAlert,
    type: state.modals.types.postAlert.type,
    content: state.modals.types.postAlert.content
  };
}

export default connect(mapStateToProps, {
  togglePostAlertModal
})(PostModalAlert);
