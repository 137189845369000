import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Landing from "views/examples/Landing.js";
import Login from "views/examples/Login.js";
import Home from "views/examples/Home.js";
import Register from "views/examples/Register.js";

import { Router } from 'react-router';

// redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './config/persistStore';
import history from './history';
import { Helmet } from 'react-helmet';

ReactDOM.render(
  <Provider store={store}>
  <Helmet>
      {/* <!-- HTML Meta Tags --> */}
      <title>alsumi.mx</title>
      <meta
        name="description"
        content="Necesitas un software? somos expertos en páginas web, aplicaciones móviles y tiendas en línea. software a la medida para tu empresa en alsumi.mx."
      />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemprop="name" content="Reed" />
      <meta
        itemprop="description"
        content="Necesitas un software? somos expertos en páginas web, aplicaciones móviles y tiendas en línea. software a la medida para tu empresa en alsumi.mx."
      />
      <meta
        itemprop="image"
        content="http://alsumi.mx/public/logo-gris-484.png"
      />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content="https://reedbarger.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Reed" />
      <meta
        property="og:description"
        content="Necesitas un software? somos expertos en páginas web, aplicaciones móviles y tiendas en línea. software a la medida para tu empresa en alsumi.mx."
      />
      <meta
        property="og:image"
        content="http://alsumi.mx/public/logo-gris-484.png"
      />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Reed" />
      <meta
        name="twitter:description"
        content="Necesitas un software? somos expertos en páginas web, aplicaciones móviles y tiendas en línea. software a la medida para tu empresa en alsumi.mx"
      />
      <meta
        name="twitter:image"
        content="http://alsumi.mx/public/logo-gris-484.png"
      />
    </Helmet>
    <PersistGate persistor={persistor}>
      <Router history={history} basename='/alsumi'>
        <Switch>
          <Route path='/' exact render={props => <Landing {...props} />} />
          <Route path='/login' exact render={props => <Login {...props} />} />
          <Route path='/home' exact render={props => <Home {...props} />} />
          <Route path='/register' exact render={props => <Register {...props} />}
          />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
