import { SET_JWT, CLEAN_JWT } from "../const/authConst";

export default (state = { jwt: null, email: '', name: '' }, action) => {
  switch (action.type) {
    case SET_JWT:
      return {
        ...state,
        jwt: action.payload.jwt,
        email: action.payload.email,
        name: action.payload.name
      };
    case CLEAN_JWT:
      return {
        ...state,
        jwt: action.payload.jwt,
        email: action.payload.email,
        name: action.payload.name
      };
    default:
      return state;
  }
};
