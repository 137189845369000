import React from "react";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button
} from "reactstrap";
// react select
import Select from 'react-select';
import PostModalAlert from '../Post/PostModalAlert';
import { connect } from 'react-redux';
import { i18 } from '../../internationalization';
import { createPost } from '../../actions/postActions';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastName: '',
      email: '',
      contactNumber: '',
      typeOfProject: -1,
      typeOfProjectName: '',
      message: '',
      errors: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.syncField = this.syncField.bind(this);
    this.syncFieldSelect = this.syncFieldSelect.bind(this);
  }

  options() {
    if (this.props.i18 === 'en') {
      return [
        { value: 0, label: 'Web Application' },
        { value: 1, label: 'Mobile Application' },
        { value: 2, label: 'Ecommerce' }
      ];
    } else {
      return [
        { value: 0, label: 'Pagina Web' },
        { value: 1, label: 'Aplicacion Movil' },
        { value: 2, label: 'Tienda' }
      ];
    }
  }

  renderPlaceholder() {
    if (this.state.typeOfProjectName === '') {
      return i18[this.props.i18].contact.form.type;
    } else {
      return this.state.typeOfProjectName;
    }
  }

  syncFieldSelect(e) {
    this.setState({typeOfProject: e.value, typeOfProjectName: e.label});
  }

  syncField(ev,fieldName){
    let element = ev.target;
    let value = element.value;

    let jsonState = {};
    jsonState[fieldName] = value;
    this.setState(jsonState);
  }

  onSubmit(e) {
    e.preventDefault();
    let bool = this.validations(this.state.name,this.state.lastName,this.state.email,
               this.state.contactNumber,this.state.typeOfProject,this.state.message);
    if(!bool){
      let params = this.getParams();
      this.props.createPost(params);
      this.setState({
        name: '',
        lastName: '',
        email: '',
        contactNumber: '',
        typeOfProject: '',
        typeOfProjectName: 'Type Of Project',
        message: '',
        errors: {},
        fieldName: '',
        fieldEmail: '',
        fieldContactNumber: '',
        fieldType: '',
        fieldLastName: '',
        fieldMessage: ''
      });
    }
  }

  getParams() {
    return {
      first_name: this.state.name,
    	last_name: this.state.lastName,
    	email: this.state.email,
    	contact_number: this.state.contactNumber,
    	project_type: this.state.typeOfProject,
    	description: this.state.message
    }
  }

  validations(name, lastName, email, contactNumber, typeOfProject, message) {
    let bool = false;
    let emailRegexBool = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    let errors = {};
    let fieldName = '';
    let fieldContactNumber= '';
    let fieldEmail = '';
    let fieldType = {};
    let fieldLastName = '';
    let fieldMessage = '';
    if(name === '') {
      errors["name"] = i18[this.props.i18].validations.posts.name.empty;
      fieldName = 'is-invalid';
      bool = true;
    }
    if(name.length > 255) {
      errors["name"] = i18[this.props.i18].validations.posts.name.maximum;
      fieldName = 'is-invalid';
      bool = true;
    }
    if(lastName.length > 255) {
      errors["lastName"] = i18[this.props.i18].validations.posts.lastName.maximum;
      fieldLastName = 'is-invalid';
      bool = true;
    }
    if(email.length > 255) {
      errors["email"] = i18[this.props.i18].validations.posts.email.maximum;
      fieldEmail = 'is-invalid';
      bool = true;
    }
    if(contactNumber.length !== 10) {
      errors["contactNumber"] = i18[this.props.i18].validations.posts.contactNumber.size;
      fieldContactNumber = 'is-invalid';
      bool = true;
    }
    if(contactNumber === '') {
      errors["contactNumber"] = i18[this.props.i18].validations.posts.contactNumber.empty;
      fieldContactNumber = 'is-invalid';
      bool = true;
    }
    if(!emailRegexBool) {
      errors["email"] = i18[this.props.i18].validations.posts.email.invalid;
      fieldEmail = 'is-invalid';
      bool = true;
    }
    if(email === '') {
      errors["email"] = i18[this.props.i18].validations.posts.email.empty;
      fieldEmail = 'is-invalid';
      bool = true;
    }
    if(typeOfProject < 0 || typeOfProject > 2) {
      errors["typeOfProject"] = i18[this.props.i18].validations.posts.typeProject.empty;
      fieldType = {
        control: base => ({
          ...base,
          borderColor: '#fb6340'
        })
      };
      bool = true;
    }
    if(message === '') {
      errors["message"] = i18[this.props.i18].validations.posts.message.empty;
      fieldMessage = 'is-invalid';
      bool = true;
    }
    if(message.length > 60000) {
      errors["message"] = i18[this.props.i18].validations.posts.message.maximum;
      fieldMessage = 'is-invalid';
      bool = true;
    }

    this.setState({
      errors: errors,
      fieldName: fieldName,
      fieldEmail: fieldEmail,
      fieldContactNumber: fieldContactNumber,
      fieldType: fieldType,
      fieldLastName: fieldLastName,
      fieldMessage: fieldMessage
    });
    return bool;
  }

  render() {
    return(
      <section className="section section-lg pt-lg-0 section-contact-us" id="contact-us-section">
        <Container>
          <Row className="justify-content-center mt--300">
            <Col lg="8">
              <Card className="bg-gradient-white shadow">
                <CardBody className="p-lg-5">
                  <h4 className="mb-1">{i18[this.props.i18].contact.form.title}</h4>
                  <p className="mt-0">
                    {i18[this.props.i18].contact.form.description}
                  </p>
                  <FormGroup>
                    <Input
                      placeholder={i18[this.props.i18].contact.form.name}
                      type="text"
                      value={this.state.name}
                      className={this.state.fieldName}
                      onChange={(e) => this.syncField(e,'name')}
                    />
                    <div className='invalid-feedback'>{this.state.errors["name"]}</div>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder={i18[this.props.i18].contact.form.lastName}
                      type="text"
                      value={this.state.lastName}
                      className={this.state.fieldLastName}
                      onChange={(e) => this.syncField(e,'lastName')}
                    />
                    <div className='invalid-feedback'>{this.state.errors["lastName"]}</div>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder={i18[this.props.i18].contact.form.email}
                      type="email"
                      value={this.state.email}
                      className={this.state.fieldEmail}
                      onChange={(e) => this.syncField(e,'email')}
                    />
                    <div className='invalid-feedback'>{this.state.errors["email"]}</div>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder={i18[this.props.i18].contact.form.number}
                      type="text"
                      value={this.state.contactNumber}
                      className={this.state.fieldContactNumber}
                      onChange={(e) => this.syncField(e,'contactNumber')}
                    />
                    <div className='invalid-feedback'>{this.state.errors["contactNumber"]}</div>
                  </FormGroup>
                  <FormGroup>
                    <Select
                      placeholder={this.renderPlaceholder()}
                      value={this.state.typeOfProject}
                      styles={this.state.fieldType}
                      onChange={(e) => this.syncFieldSelect(e)}
                      options={this.options()} />
                      <div className='invalid-feedback'>{this.state.errors["typeOfProject"]}</div>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      cols="80"
                      name="name"
                      placeholder={i18[this.props.i18].contact.form.message}
                      rows="4"
                      type="textarea"
                      value={this.state.message}
                      className={this.state.fieldMessage}
                      onChange={(e) => this.syncField(e,'message')}
                    />
                    <div className='invalid-feedback'>{this.state.errors["message"]}</div>
                  </FormGroup>
                  <div>
                    <Button
                      block
                      onClick={this.onSubmit}
                      className="btn-round"
                      color="secondary"
                      size="lg"
                      type="button"
                    >
                      {i18[this.props.i18].buttons.sendMessage}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <PostModalAlert/>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    i18: state.i18
  };
}

export default connect(mapStateToProps, {
  createPost
})(Form);
