import React from 'react';
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { i18 } from '../../internationalization';
import { connect } from 'react-redux';

class Offer extends React.Component {
  render() {
    return (
      <section className="section section-lg mt-9" id="offer-section">
        <Container>
          <Row className="justify-content-center text-center mb-lg mt-4">
            <Col lg="8">
              <h2 className="display-3">{i18[this.props.i18].offer.title}</h2>
              <p className="lead text-muted">
                {i18[this.props.i18].offer.description}
              </p>
            </Col>
          </Row>
          <Row id="services-free">
            <Col className="mb-5" lg="4" md="6">
              <div className="px-4">
                <img
                  alt="..."
                  className="img-center"
                  src={require("assets/img/icons/common/propuesta-2.png")}
                />
                <p>{i18[this.props.i18].offer.first}</p>
              </div>
            </Col>
            <Col className="mb-5" lg="4" md="6">
              <div className="px-4">
                <img
                  alt="..."
                  className="img-center"
                  src={require("assets/img/icons/common/capacitacion-2.png")}
                />
                <p>{i18[this.props.i18].offer.second}</p>
              </div>
            </Col>
            <Col className="mb-5" lg="4" md="6">
              <div className="px-4">
                <img
                  alt="..."
                  className="img-center"
                  src={require("assets/img/icons/common/analisis-2.png")}
                />
                <p>{i18[this.props.i18].offer.third}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    i18: state.i18
  };
}

export default connect(mapStateToProps, {})(Offer);
