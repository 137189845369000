import { SET_POST_PAGINATION, SET_PREVIOUS_PAGE, SET_NEXT_PAGE } from "../const/postConst";

export default (state = { pagination: {}, previousPage: false, nextPage: false }, action) => {
  switch (action.type) {
    case SET_POST_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case SET_PREVIOUS_PAGE:
      return {
        ...state,
        previousPage: action.payload
      };
    case SET_NEXT_PAGE:
      return {
        ...state,
        nextPage: action.payload
      };
    default:
      return state;
  }
};
