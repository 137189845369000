import React from 'react';
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { i18 } from '../../internationalization';
import { connect } from 'react-redux';

class ContactUs extends React.Component {
  render() {
    return (
      <section className="section section-lg" id="conctact-us-section">
       <Container className="pb-300">
         <Row className="text-center justify-content-center">
           <Col lg="12">
             <h2 className="display-3 text-white mt-2">{i18[this.props.i18].contact.title}</h2>
             <p className="lead text-white">
               {i18[this.props.i18].contact.description}
             </p>
           </Col>
         </Row>
         <Row className="text-center justify-content-center mt-5">
           <Col lg="4">
             <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
               <i className="ni ni-mobile-button text-primary" />
             </div>
             <h5 className="text-white mt-3">{i18[this.props.i18].contact.number.title.cancun}</h5>
             <p className="text-white mt-3">
               {i18[this.props.i18].contact.number.cancun}
             </p>
           </Col>
           <Col lg="4">
             <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
               <i className="ni ni-laptop text-primary" />
             </div>
             <h5 className="text-white mt-3">{i18[this.props.i18].contact.email.title}</h5>
             <p className="text-white mt-3">
               {i18[this.props.i18].contact.email.value}
             </p>
           </Col>
           <Col lg="4">
             <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
               <i className="ni ni-email-83 text-primary" />
             </div>
             <h5 className="text-white mt-3">{i18[this.props.i18].contact.number.title.ver}</h5>
             <p className="text-white mt-3">
               {i18[this.props.i18].contact.number.ver}
             </p>
           </Col>
         </Row>
       </Container>
       {/* SVG separator */}
       <div className="separator separator-bottom separator-skew zindex-100">
         <svg
           xmlns="http://www.w3.org/2000/svg"
           preserveAspectRatio="none"
           version="1.1"
           viewBox="0 0 2560 100"
           x="0"
           y="0"
         >
           <polygon
             className="fill-white"
             points="2560 0 2560 100 0 100"
           />
         </svg>
       </div>
      </section>
    );
  }
};


function mapStateToProps(state, ownProps) {
  return {
    i18: state.i18
  };
}

export default connect(mapStateToProps, {})(ContactUs);
