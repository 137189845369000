import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import history from '../../history';

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
// toast
import { ToastContainer } from "react-toastify";
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.syncField = this.syncField.bind(this);
  }

  UNSAFE_componentWillMount() {
    var jwt = this.props.auth.jwt;
    if (jwt !== null) {
      history.push('home');
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  syncField(ev,fieldName){
    let element = ev.target;
    let value = element.value;

    let jsonState = {};
    jsonState[fieldName] = value;
    this.setState(jsonState);
  }

  validations(email, password) {
    let bool = false;
    let emailRegexBool = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    let errors = {};
    let fieldEmail = '';
    let fieldPassword = '';
    if(email === '') {
      errors["email"] = "Email field is required";
      fieldEmail = 'is-invalid';
      bool = true;
    }
    if(password === '') {
      errors["password"] = "Password field is required";
      fieldPassword = 'is-invalid';
      bool = true;
    }
    if(!emailRegexBool) {
      errors["email"] = "Email format not valid";
      fieldEmail = 'is-invalid';
      bool = true;
    }

    this.setState({errors: errors, fieldEmail: fieldEmail, fieldPassword: fieldPassword});
    return bool;
  }

  getParams() {
    return {
    	email: this.state.email,
    	password: this.state.password
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let bool = this.validations(this.state.email, this.state.password);
     if(!bool){
       let params = this.getParams();
       this.props.loginUser(params);
     }
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section-profile-cover section-shaped my-0">
            <div className="shape">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="shadow border-0 mt-5">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Sign In</small>
                      </div>
                      <Form role="form">
                        <FormGroup className="mb-3">
                            <Input
                              placeholder="Email"
                              type="email"
                              value={this.state.email}
                              className={this.state.fieldEmail}
                              onChange={(e) => this.syncField(e,'email')}
                            />
                          <div className='invalid-feedback'>{this.state.errors["email"]}</div>
                        </FormGroup>
                        <FormGroup>
                            <Input
                              placeholder="Password"
                              type="password"
                              autoComplete="off"
                              value={this.state.password}
                              className={this.state.fieldPassword}
                              onChange={(e) => this.syncField(e,'password')}
                            />
                          <div className='invalid-feedback'>{this.state.errors["password"]}</div>
                        </FormGroup>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="secondary"
                            type="button"
                            onClick={this.onSubmit}
                          >
                            Sign in
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <ToastContainer autoClose={2000} />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.user,
  };
}

export default connect(mapStateToProps,
  { loginUser }
)(Login);
