import React from 'react';
import Moment from 'react-moment';
import Select from 'react-select';
import { updatePost  } from '../../actions/postActions';
import { connect } from 'react-redux';

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.syncFieldSelect = this.syncFieldSelect.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  customStyles() {
    const customStyles = {
      control: base => ({
        ...base,
        height: 44,
        width: 140
      })
    };
    return customStyles;
  }

  options() {
   const options = [
       { value: 0, label: 'Created' },
       { value: 1, label: 'Seen' },
       { value: 2, label: 'Communication' },
       { value: 3, label: 'Working' },
       { value: 4, label: 'Finished' },
       { value: 5, label: 'Rejected' }
     ];
     return options;
  }

  renderStatusValue(status) {
    var obj = this.options().find(o => o.label.toLowerCase() === status);
    return obj.value;
  }

  renderCheckbox(seen_at, id) {
    var bool = seen_at === null ? false : true;
    return <input
              className="custom-control-input"
              defaultChecked={bool}
              type="checkbox"
              disabled={bool}

              onClick={ () => this.handleCheckboxChange(id) }
            />;
  }

  handleCheckboxChange(id) {
    var date = new Date();
    // status 1 means created in server
    var params = { seen_at: date, status: 1 };
    var headers = this.getHeaders();
    this.props.updatePost(id, params, headers);
  }

  renderSeenAtTime(seen_at) {
    if (seen_at === null) {
      return '';
    } else {
      return <Moment format="YYYY/MM/DD hh:mm:ss">{seen_at}</Moment>;
    }
  }

  renderIconType(project_type) {
    if (project_type === 'web') {
      return <i className="fa fa-2x fa-laptop"></i>;
    }
    else if (project_type === 'mobile') {
      return <i className="fa fa-2x fa-mobile"></i>;
    }
    else if (project_type === 'ecommerce') {
      return <i className="fa fa-2x fa-shopping-cart"></i>;
    }
  }

  syncFieldSelect(id,e) {
    var params = { status: e.value };
    var headers = this.getHeaders();
    this.props.updatePost(id, params, headers);
  }

  getHeaders() {
    return {
      Authorization: 'application/json',
     "access-token": this.props.auth.jwt.token,
     client: this.props.auth.jwt.client,
     uid: this.props.auth.email
    };
  }

  render() {
    return(
      <tr>
        <th>{this.props.post.id}</th>
        <td>{this.props.post.first_name + ' ' + this.props.post.last_name}</td>
        <td>{this.props.post.contact_number}</td>
        <td>{this.props.post.email}</td>
        <td className="text-center">{ this.renderIconType(this.props.post.project_type) }</td>
        <td>{this.props.post.description}</td>
        <td><Moment format="YYYY/MM/DD hh:mm:ss">{this.props.post.created_at}</Moment></td>
        <td><Select
          styles={this.customStyles()}
          placeholder={this.props.post.status}
          value={this.renderStatusValue(this.props.post.status)}
          defaultValue={this.renderStatusValue(this.props.post.status)}
          onChange={(e) => this.syncFieldSelect(this.props.post.id, e)}
          options={this.options()} /></td>
        <td>
          <div className="custom-control custom-checkbox mb-3">
            { this.renderCheckbox(this.props.post.seen_at, this.props.post.id) }
          </div>
        </td>
        <td>{ this.renderSeenAtTime(this.props.post.seen_at) }</td>
      </tr>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.user,
  };
}

export default connect(mapStateToProps, {
  updatePost
})(Post);
