import { GET_POSTS, SET_POST } from "../const/postConst";
import _ from 'lodash';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_POSTS:
      return _.mapKeys(action.payload, 'id');
    case SET_POST:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};
