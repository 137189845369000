import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from '../reducers';
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user'] // import reducers you want to persist
};
var middleware;
if (process.env.NODE_ENV !== 'production') {
    middleware = applyMiddleware(thunk, logger);
} else {
  middleware = applyMiddleware(thunk);
}
const pReducer = persistReducer(persistConfig, reducers);
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);
export { persistor, store };
