import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// toast
import { ToastContainer } from "react-toastify";
// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";

import { createUser } from '../../actions/authActions.js';
import history from '../../history';
// redux
import { connect } from 'react-redux';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      token: '',
      errors: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.syncField = this.syncField.bind(this);
  }

  UNSAFE_componentWillMount() {
    var jwt = this.props.auth.jwt;
    if (jwt !== null) {
      history.push('home');
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  syncField(ev,fieldName){
    let element = ev.target;
    let value = element.value;

    let jsonState = {};
    jsonState[fieldName] = value;
    this.setState(jsonState);
  }

  validations(name, email, password, token) {
    let bool = false;
    let emailRegexBool = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    let errors = {};
    let fieldName = '';
    let fieldEmail = '';
    let fieldPassword = '';
    let fieldToken = '';
    if(name === '') {
      errors["name"] = "Name field is required";
      fieldName = 'is-invalid';
      bool = true;
    }
    if(email === '') {
      errors["email"] = "Email field is required";
      fieldEmail = 'is-invalid';
      bool = true;
    }
    if(password === '') {
      errors["password"] = "Password field is required";
      fieldPassword = 'is-invalid';
      bool = true;
    }
    if(token === '') {
      errors["token"] = "Token field is required";
      fieldToken = 'is-invalid';
      bool = true;
    }
    if(!emailRegexBool) {
      errors["email"] = "Email format not valid";
      fieldEmail = 'is-invalid';
      bool = true;
    }

    this.setState({
      errors: errors,
      fieldName: fieldName,
      fieldEmail: fieldEmail,
      fieldPassword: fieldPassword,
      fieldToken: fieldToken
    });
    return bool;
  }

  getParams() {
    return {
      name: this.state.name,
    	email: this.state.email,
    	password: this.state.password,
    	token: this.state.token
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let bool = this.validations(this.state.name,this.state.email,
               this.state.password,this.state.token);
     if(!bool) {
       let params = this.getParams();
       this.props.createUser(params);
     }
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Sing Up</small>
                      </div>
                      <Form role="form">
                        <FormGroup>

                          <Input
                            placeholder="Name"
                            type="text"
                            value={this.state.name}
                            className={this.state.fieldName}
                            onChange={(e) => this.syncField(e,'name')}
                          />
                          <div className='invalid-feedback'>{this.state.errors["name"]}</div>
                        </FormGroup>
                        <FormGroup>
                          <Input
                            placeholder="Email"
                            type="email"
                            value={this.state.email}
                            className={this.state.fieldEmail}
                            onChange={(e) => this.syncField(e,'email')}
                          />
                          <div className='invalid-feedback'>{this.state.errors["email"]}</div>
                        </FormGroup>
                        <FormGroup>
                          <Input
                            placeholder="Password"
                            type="password"
                            autoComplete="off"
                            value={this.state.password}
                            className={this.state.fieldPassword}
                            onChange={(e) => this.syncField(e,'password')}
                          />
                          <div className='invalid-feedback'>{this.state.errors["password"]}</div>
                        </FormGroup>
                        <FormGroup>
                          <Input
                            placeholder="Token"
                            type="password"
                            autoComplete="off"
                            value={this.state.token}
                            className={this.state.fieldToken}
                            onChange={(e) => this.syncField(e,'token')}
                          />
                          <div className='invalid-feedback'>{this.state.errors["token"]}</div>
                        </FormGroup>
                        <div className="text-center">
                          <Button
                            className="mt-4"
                            color="secondary"
                            type="button"
                            onClick={this.onSubmit}
                          >
                            Create account
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <ToastContainer autoClose={2000} />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.user,
  };
}

export default connect(mapStateToProps,{
  createUser
})(Register);
