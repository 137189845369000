import { GET_POSTS, SET_POST,
        GET_SUMMARY, SET_POST_PAGINATION,
        SET_PREVIOUS_PAGE, SET_NEXT_PAGE } from "../const/postConst";
import { CLEAN_JWT } from "../const/authConst";
import http from '../apis/alsumi';
import history from '../history';
import { showToast } from '../toast/toastify';
import { i18 } from '../internationalization';

import { togglePostAlertModal, setContentPostModal  } from './modalActions';

export const setPosts = (page, headers, params={}) => async (dispatch, getState) => {
  try {
    var response = await http.get(`/posts?page=${page}`, { headers: headers, params: params });
    var result = response.data;
    var posts = result.data.posts;
    var pagination = result.data.pagy;
    var pages = pagination.pages;
    var currentPage = pagination.page;

    if (currentPage === 1 && pages === 1) {
      dispatch({ type: SET_PREVIOUS_PAGE, payload: true });
      dispatch({ type: SET_NEXT_PAGE, payload: true });
    }
    else if (currentPage === 1) {
      dispatch({ type: SET_PREVIOUS_PAGE, payload: true });
      dispatch({ type: SET_NEXT_PAGE, payload: false });
    }
    else if (currentPage === pages) {
      dispatch({ type: SET_NEXT_PAGE, payload: true });
      dispatch({ type: SET_PREVIOUS_PAGE, payload: false });
    }
    else if (currentPage > 1 && currentPage < pages) {
      var previousPage = getState().postPagination.previousPage;
      var nextPage = getState().postPagination.nextPage;
      if (previousPage || nextPage) {
        dispatch({ type: SET_NEXT_PAGE, payload: false });
        dispatch({ type: SET_PREVIOUS_PAGE, payload: false });
      }
    }

    dispatch({ type: GET_POSTS, payload: posts });
    dispatch({ type: SET_POST_PAGINATION, payload: pagination });
  } catch (e) {
    var data = { jwt: null, email: '', name: '' };
    dispatch({ type: CLEAN_JWT, payload: data });
    history.push('login');
  }
};

export const setSummary = (headers) => async dispatch => {
  try {
    var response = await http.get('/posts/summary', { headers: headers });
    var result = response.data;
    var summary = {read: result.data.read, unread: result.data.unread, total: result.data.read + result.data.unread};
    dispatch({ type: GET_SUMMARY, payload: summary });
  } catch (e) {
    var data = { jwt: null, email: '', name: '' };
    dispatch({ type: CLEAN_JWT, payload: data });
    history.push('login');
  }
};

export const createPost = (params) => async (dispatch, getState) => {
  try {
    var response = await http.post('/posts', params);
    let content = {
      type: 'success',
      content: {
        header: i18[getState().i18].actions.posts.create.sucess.header,
        message: i18[getState().i18].actions.posts.create.sucess.message,
        text: i18[getState().i18].actions.posts.create.sucess.text
      }
    };
    dispatch(setContentPostModal(content));
    dispatch(togglePostAlertModal());
  } catch (e) {
    console.log(e);
    // net::ERR_CONNECTION_TIMED_OUT
    var content = {};
    if (e.response === undefined) {
      content = {
        type: 'danger',
        content: {
          header: i18[getState().i18].actions.posts.create.error.header,
          message: i18[getState().i18].actions.posts.create.error.message,
          text: i18[getState().i18].actions.posts.create.error.text,
          errors: []
        }
      };
    } else {
      let error = e.response;
      let result = error.data;
      content = {
        type: 'danger',
        content: {
          header: i18[getState().i18].actions.posts.create.error.header,
          message: i18[getState().i18].actions.posts.create.error.message,
          text: i18[getState().i18].actions.posts.create.error.text,
          errors: result.data.errors
        }
      };
    }
    dispatch(setContentPostModal(content));
    dispatch(togglePostAlertModal());
  }
}

export const updatePost = (id, params, headers) => async dispatch => {
  try {
    var response = await http.patch('/posts/'+id+'', params, { headers: headers });
    console.log(response.data);
    var result = response.data;
    dispatch({ type: SET_POST, payload: result.data.post });
  } catch (e) {
    var error = e.response;
    var alert = {};
    if (error === undefined) {
      alert = {
        error: true,
        message: 'There was an error connecting to the server',
        errors: ''
      };
    } else {
      alert  = {
        error: error.data.error,
        message: error.data.data.message,
        errors: error.data.data.errors
      }
    }
    showToast(alert);
  }
};
