import React from 'react';
import {
  Badge,
  Card,
  CardBody,
  Container,
  Row,
  Col
 } from "reactstrap";
 import { connect } from 'react-redux';
 import { i18 } from '../../internationalization';

class ServicesHeader extends React.Component {
  render() {
    return (
      <div className="position-relative">
      <section className="section section-lg section-shaped pb-250" id="services-section">
        <div className="shape shape-primary">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="py-lg-md d-flex">
          <div className="col px-0">
            <Row>
              <Col lg="12 header-title">
                <h1 className="display-2 text-secondary mt-5">
                  {i18[this.props.i18].title.main}
                  <span></span>
                </h1>
                <div className='small-container'>
                  <p>{i18[this.props.i18].title.second}</p>
                  <p>{i18[this.props.i18].title.third}</p>
                  <p>{i18[this.props.i18].title.fourth}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="fill-white"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </section>
      <section className="section section-lg pt-lg-0 mt--200">
        <Container>
          <Row className="justify-content-center mt-4">
            <Col lg="12">
              <Row className="row-grid">
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <div className="text-center icon-header mb-4">
                        <i className="ni ni-tv-2 text-danger" />
                      </div>
                      <h6 className="text-danger text-uppercase">
                        {i18[this.props.i18].cards.web.title}
                      </h6>
                      <p className="description mt-3 text-secundary">
                        {i18[this.props.i18].cards.web.description}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <div className="icon-header rounded-circle mb-4">
                        <i className="ni ni-shop text-info" />
                      </div>
                      <h6 className="text-info text-uppercase">
                        {i18[this.props.i18].cards.ecommerce.title}
                      </h6>
                      <p className="description mt-3">
                        {i18[this.props.i18].cards.ecommerce.description}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-center">
                      <div className="icon-header rounded-circle mb-4">
                        <i className="ni ni-mobile-button text-warning" />
                      </div>
                      <h6 className="text-warning text-uppercase">
                        {i18[this.props.i18].cards.mobile.title}
                      </h6>
                      <p className="description mt-3">
                        {i18[this.props.i18].cards.mobile.description}
                      </p>
                      <div>
                        <Badge color="warning" pill className="mr-1">
                          Android
                        </Badge>
                        <Badge color="warning" pill className="mr-1">
                          IOS
                        </Badge>
                        <Badge color="warning" pill className="mr-1">
                          PWA
                        </Badge>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    i18: state.i18
  };
}

export default connect(mapStateToProps, {})(ServicesHeader);
