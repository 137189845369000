/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import { i18 } from '../../internationalization';
import { connect } from 'react-redux';

class Technology extends React.Component {
  render() {
    return (
      <>
        <section className="section section-lg mt-3">
          <Container>
            <Row className="row-grid justify-content-center" id="technologies-icons">
              <Col className="text-center" lg="12">
                <div className="text-center">
                  <h1 className="mb-5 mt-4">
                    {i18[this.props.i18].technology.title}
                  </h1>
                  <Row className="justify-content-center">
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/vue.svg")}
                      />
                    </div>
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/angular.svg")}
                      />
                    </div>
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/react.svg")}
                      />
                    </div>
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/ruby.svg")}
                      />
                    </div>
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/postgres.svg")}
                      />
                    </div>
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/node-2.svg")}
                      />
                    </div>
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/mongo.svg")}
                      />
                    </div>
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/laravel.svg")}
                      />
                    </div>
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/firebase.svg")}
                      />
                    </div>
                    <div>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("assets/img/technologies/django.svg")}
                      />
                    </div>
                  </Row>
                </div>
                <p className="lead">
                  {i18[this.props.i18].technology.description}
                </p>
                <div className="btn-wrapper">
                  <Button
                    size="lg"
                    className="mb-3 mb-sm-0"
                    color="secondary"
                    href="#contact-us-section"
                  >
                    {i18[this.props.i18].buttons.sendMessage}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    i18: state.i18
  };
}

export default connect(mapStateToProps, {})(Technology);
