import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { setPosts  } from '../../actions/postActions';
import Post from './Post';

class PostList extends React.Component {
  UNSAFE_componentWillMount() {
    var headers = this.getHeaders();
    var page = 1; // this is first page pagination
    this.props.setPosts(page, headers);
  }

  getHeaders() {
    return {
      Authorization: 'application/json',
     "access-token": this.props.auth.jwt.token,
     client: this.props.auth.jwt.client,
     uid: this.props.auth.email
    };
  }

  renderList() {
    return this.props.posts.map(post => {
      return (
        <Post post={post} key={post.id} />
      );
    });
  }

  render() {
    return(
        <>
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Number</th>
                <th>Email</th>
                <th>Type</th>
                <th>Description</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Seen</th>
                <th>Seent At</th>
              </tr>
            </thead>
            <tbody>
              {this.renderList()}
            </tbody>
          </Table>
        </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    auth: state.user,
    posts: Object.values(state.posts)
  };
}

export default connect(mapStateToProps, {
  setPosts
})(PostList);
