import { GET_SUMMARY } from "../const/postConst";

export default (state = {read: 0, unread: 0, total: 0}, action) => {
  switch (action.type) {
    case GET_SUMMARY:
      return {
        ...state,
        read: action.payload.read,
        unread: action.payload.unread,
        total: action.payload.total };
    default:
      return state;
  }
};
