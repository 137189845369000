import React from 'react';
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { i18 } from '../../internationalization';
import { connect } from 'react-redux';

class AboutUs extends React.Component {
  render() {
    return (
      <section className="section section-lg mt-5" id="about-us-section">
        <Container>
        <Row>
            <Col className="mb-5 mb-lg-0 mt-6" lg="12" md="12">
              <div className="px-4">
                <img
                  alt="..."
                  className="img-center img-fluid shadow-lg--hover"
                  src={require("assets/img/brand/logo-gris-484.png")}
                  style={{ width: "200px" }}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center text-center mb-lg">
            <Col lg="12">
              <p className="lead text-muted">
                {i18[this.props.i18].about.descriptionOne}
              </p>
              <p className="lead text-muted">
                {i18[this.props.i18].about.descriptionTwo}
              </p>
              <p className="lead text-muted">
                {i18[this.props.i18].about.descriptionThree}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    i18: state.i18
  };
}

export default connect(mapStateToProps, {})(AboutUs);
